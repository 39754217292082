import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {AboutSection, GetInTouchSection, HeroSection, TechSection} from "../sections";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          "freelance developer",
          "software engineer remote",
          "react developer",
          "node.js developer",
          "backend engineer",
          "AWS Developer",
          "freelance software engineer"
        ]}
      />

      <HeroSection />
      <AboutSection />
      <TechSection />
      <GetInTouchSection />
    </Layout>
  );
}

export default IndexPage;
