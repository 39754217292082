import React from "react";

function GetInTouch() {
  return (
    <div className="flex flex-1 items-center flex-wrap flex-col py-16 bg-secondary text-white">
      <div className="lg:w-2/3 flex flex-col sm:flex-row items-center mx-auto px-4 max-w-7xl">
        <h1 className="flex-grow sm:pr-16 sm:text-2xl text-center sm:text-left font-medium title-font">
          Interested in working together? I am always open for a chat.
        </h1>
        <a
          href="mailto:simranjeet@s25.digital?subject=Interested%20in%20working%20together&body=Hi%20Simranjeet%2C%0D%0A"
          className="flex-shrink-0 text-white bg-action border-0 py-2 px-8 focus:outline-none rounded text-lg mt-10 sm:mt-0"
        >
          Say Hello
        </a>
      </div>
    </div>
  );
}

export default GetInTouch;
