import React from "react";

function AboutSection() {
  return (
    <div className="flex items-center flex-wrap flex-col py-16 bg-primary text-white">
      <div className="sm:max-w-xl md:max-w-4xl">
        <div className="px-6 py-4">
          <h2 className="inline-block p-3 m-auto md:text-2xl font-bold text-center w-full sm:text-xl">
            About me
          </h2>
          <span className="mb-2 text-left text-base md:text-lg leading-6">
            I am a fullstack engineer, web and mobile app developer and an amateur
            designer. I have been building applications and systems from the
            best part of {new Date().getFullYear() - 2014} years now. I have
            done remote work for startups and enterprise, consulted startups
            with architecture and infrastructure, worked for agencies and
            collaborated with talented people to bring good digital products for
            both business and consumers to life. <br />I love converting ideas
            to digital products and have a product studio to provide services
            and bring my ideas to reality. You can see it at{" "}
            <a href="https://s25.digital" className="font-semibold underline">
              https://s25.digital
            </a>.
          </span>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
