import React from "react";

import {
  SiTypescript,
  SiJavascript,
  SiMongodb,
  SiPostgresql,
  SiAmazonaws,
  SiTerraform,
  SiNextdotjs,
  SiNodedotjs,
  SiGraphql,
  SiDocker,
  SiGatsby,
  SiReact,
  SiShopify,
  SiDigitalocean,
} from "react-icons/si";

function GetInTouch() {
  return (
    <div className="flex flex-1 items-center flex-wrap flex-col py-16 bg-white">
      <div className="flex flex-row flex-wrap items-center max-w-7xl p-4">
        <span className="p-2 m-2 cursor-pointer">
          <SiTypescript size={48} title="Typescript" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiJavascript size={48} title="Javascript" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiNodedotjs size={48} title="Node.js" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiMongodb size={48} title="Mongodb" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiPostgresql size={48} title="PostgreSQL" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiAmazonaws size={48} title="AWS" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiDigitalocean size={48} title="Digital Ocean" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiTerraform size={48} title="Terrafrom" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiGraphql size={48} title="GraphQL" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiDocker size={48} title="Docker" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiReact size={48} title="React + React-Native" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiNextdotjs size={48} title="Next.js" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiGatsby size={48} title="Gatsby" />
        </span>
        <span className="p-2 m-2 cursor-pointer">
          <SiShopify size={48} title="Shopify" />
        </span>
      </div>
    </div>
  );
}

export default GetInTouch;
